import { lazy } from "react";

export const RouteList = [
  {
    path: "/",
    component: lazy(async () => await import("../pages/home/home")),
  },

  {
    path: "/user-management",
    component: lazy(async () => await import("../pages/user/index")),
    exact: true,
  },

  {
    path: "/feedback",
    component: lazy(async () => await import("../pages/feedback/index")),
    exact: true,
  },
  {
    path: "/update",
    component: lazy(async () => await import("../pages/update/index")),
    exact: true,
  },
  {
    path: "/growth-Table",
    component: lazy(
      async () => await import("../pages/growthDefaultView/index")
    ),
    exact: true,
  },
  // Page Not Found
  {
    path: "/page-not-found",
    component: lazy(async () => await import("../pages/pageNotFound")),
    exact: true,
  },
];
// import { lazy } from "react";

// export const RouteList = [
//   {
//     path: "/",
//     component: lazy(async () => await import("../pages/home/home")),
//     children: [
//       {
//         index: true,
//         component: lazy(
//           async () => await import("../pages/Strategic-Imperative/index")
//         ),
//       },
//       {
//         path: "strategic-imperative",
//         component: lazy(
//           async () => await import("../pages/Strategic-Imperative/index")
//         ),
//       },
//       {
//         path: "growth-opportunity",
//         component: lazy(
//           async () => await import("../pages/growthOpportunity/index")
//         ),
//       },
//     ],
//   },
//   {
//     path: "/user-management",
//     component: lazy(async () => await import("../pages/user/index")),
//     exact: true,
//   },
//   {
//     path: "/feedback",
//     component: lazy(async () => await import("../pages/feedback/index")),
//     exact: true,
//   },
//   {
//     path: "/update",
//     component: lazy(async () => await import("../pages/update/index")),
//     exact: true,
//   },
//   {
//     path: "/page-not-found",
//     component: lazy(async () => await import("../pages/pageNotFound")),
//     exact: true,
//   },
// ];

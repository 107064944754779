import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./App.css";
import CustomModal from "./components/modal";
import {
  REDIRECT_PERMISSION_MESSAGE,
  RELOAD_PERMISSION_MESSAGE,
} from "./constants/common";
import useCustomModal from "./hooks/customHooks/useCusomModal";
import useFrostSi8Api from "./hooks/useFrostSi8Api";
import { selectToken } from "./pages/auth/authSlice";
import {
  selectAuthRoutesList,
  setRouteList,
} from "./pages/configuration/routeSlice";
import RoutesContainer from "./routes/RoutesContainer";
import AuthService from "./services/authService";
import { AbpApplicationConfigurationService } from "./services/frost-si8-services";
import { getFilteredPermissionData } from "./utils";
import { ConfigProvider } from "antd";
import theme from "./constants/style/theme";

function App() {
  const token = useSelector(selectToken);
  const { handleRequest } = useFrostSi8Api();
  const authService = new AuthService();
  const dispatch = useDispatch();
  const [permissionStatus, setPermissionStatus] = useState<string>("");
  const { showModal, openModal, closeModal } = useCustomModal();

  const userAuthList = useSelector(selectAuthRoutesList);

  const username = `${userAuthList?.currentUser?.userName}`;

  useEffect(() => {
    if (!userAuthList) {
      return; // Exit early if userAuthList is undefined
    }
  }, [userAuthList]);

  useEffect(() => {
    if (username !== "undefined") {
      const script = document.createElement("script");
      script.src = `${window?.__RUNTIME_CONFIG__?.AI_GROWTH_BASE_URL}/examples/embedded-web-chat/inject.js`;
      // script.async = true;

      script.onload = () => {
        const baseUrl = window?.__RUNTIME_CONFIG__?.AI_GROWTH_BASE_URL;
        const frontBaseUrl =
          window?.__RUNTIME_CONFIG__?.AI_GROWTH_FRONT_BASE_URL;
        const tenantShortCode =
          window?.__RUNTIME_CONFIG__?.AI_GROWTH_TENANTSHORT_CODE;
        const projectId = window?.__RUNTIME_CONFIG__?.AI_GROWTH_PROJECTID;
        const projectIdentifier =
          window?.__RUNTIME_CONFIG__?.AI_GROWTH_PROJECTIDENTIFIER;
        const apikey = window?.__RUNTIME_CONFIG__?.AI_GROWTH_PROJECTAPIKEY;

        const knowledgeConfig = {
          host: frontBaseUrl,
          projectId: projectId,
          apiKey: apikey,
          username: username,
          baseUrl: baseUrl,
          projectIdentifier: projectIdentifier,
          tenantShortCode: tenantShortCode,
        };
        window["initializeChatWidget"](knowledgeConfig);
      };

      document.body.appendChild(script);

      // Cleanup function
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [username]);

  useEffect(() => {
    if (token?.length > 0) {
      handleRequest(
        AbpApplicationConfigurationService.getCoreApiApiAbpApplicationConfiguration()
      )
        .then((response) => {
          const { nonFieldLevelPermissionsGroups } = getFilteredPermissionData(
            response?.auth?.grantedPolicies,
            "User"
          );

          const RouteList = Object.keys(nonFieldLevelPermissionsGroups).map(
            (item) => item
          );

          dispatch(
            setRouteList({
              currentUserAuthList: response ?? {},
              currentUserRoutesList: RouteList ?? [],
            })
          );
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [token]);

  window.addEventListener("PERMISSION_CHANGED", (evt: any) => {
    setPermissionStatus(evt.detail);
    openModal();
  });

  const handleCloseModal = () => {
    closeModal();
    if (permissionStatus === "SIGN_IN") {
      authService.logout();
    }
  };

  return (
    <>
      <ConfigProvider theme={theme}>
        {showModal && (
          <CustomModal
            style={{ display: "flex" }}
            show={showModal}
            size={"sm"}
            title={"message"}
            children={
              permissionStatus === "RELOAD"
                ? RELOAD_PERMISSION_MESSAGE
                : REDIRECT_PERMISSION_MESSAGE
            }
            onHide={() => handleCloseModal()}
          />
        )}
        <RoutesContainer />
      </ConfigProvider>
    </>
  );
}

export default App;

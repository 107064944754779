import { FooterWrapper } from "./footer.styled";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <FooterWrapper>
      <div className="container">
        <div className="footer-nav">
          <div className="footer-nav-start">
            <p className="contact-us">
              <i className="material-icons">mail_outline</i> Support:
              tm.support@frost.com{" "}
            </p>
          </div>
          <div className="footer-nav-end">
            <p>© {year} Frost & Sullivan | All Rights Reserved </p>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;

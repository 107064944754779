import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

export const FooterWrapper = styled.footer`
  background: ${colors.secondary};
  color: ${colors.white};
  display: block;
  font-size: 9px;
  height: 5%;
  line-height: normal;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 0.5rem 0;
  }

  .container {
    height: 100%;
    max-width: none;
    padding: 5px;
    display: flex;
    align-items: center;
  }
  .footer-nav {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &-start {
      display: flex;
      align-items: center;
      width: 40%;

      .contact-us {
        display: flex;
        align-items: center;
        margin: 0;
        line-height: normal;

        .material-icons {
          margin-right: 8px;
        }
      }
    }
    &-end {
      display: flex;
      align-items: center;
      width: 60%;
      color: yellow;

      p {
        margin: 0;
        line-height: normal;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;

      &-start,
      &-end {
        width: 100%;
        justify-content: center;
        text-align: center;
      }
    }
  }
`;

import moment from "moment";
import { colors } from "../../constants/themeConstants";
import { stylePrimary } from "../../utils/style";
import { CustomLoader, LoaderWrapper, ViewWrapper } from "./update.styled";
import parse from "html-react-parser";

interface AddEditFeedbackProps {
  rowData?: any;
  closeUpdate: () => void;
  setDataViewOnly?: (bool: boolean) => void; // Add a default value
  dataViewOnly?: boolean;
}

const UpdateView = ({
  rowData,
  closeUpdate,
  setDataViewOnly = () => {},
  dataViewOnly,
}: AddEditFeedbackProps) => {
  const description = rowData?.description
    ? rowData?.description?.replace(/(\n)/g, "<br>")
    : rowData?.message?.replace(/(\n)/g, "<br>");
  return !rowData && dataViewOnly ? (
    <LoaderWrapper>
      <CustomLoader className="loader" color={colors.secondary}></CustomLoader>
    </LoaderWrapper>
  ) : (
    <ViewWrapper notif={true}>
      <div className="header">
        <h2 className="title">{rowData?.title}</h2>
        <div className="version-time">
          <span className="time">
            Date : {moment(rowData?.creationTime).format("YYYY-MM-DD")}
          </span>
          <span className="version"> Version :{rowData?.version}</span>
        </div>
      </div>
      <p className="description">{parse(description)}</p>
      <button
        onClick={() => {
          closeUpdate();
          setTimeout(() => {
            setDataViewOnly(false);
          }, 500);
        }}
        type="button"
        style={stylePrimary}
        className="cancelButton"
      >
        Close
      </button>
    </ViewWrapper>
  );
};

export default UpdateView;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SubSegmentState {
  edited: boolean;
  generating: boolean;
  newGenerated: boolean;
  generatedSegment: string | null; // Adding a field to hold the generated segment
}

const initialState: SubSegmentState = {
  edited: false,
  generating: false,
  newGenerated: false,
  generatedSegment: null, // Initialized to null
};

const subSegmentSlice = createSlice({
  name: "subSegment",
  initialState,
  reducers: {
    setUpdateFlag: (state, action: PayloadAction<boolean>) => {
      state.edited = action.payload;
    },
    setGenerating: (state, action: PayloadAction<boolean>) => {
      state.generating = action.payload;
    },
    setNewGenerated: (state, action: PayloadAction<boolean>) => {
      state.newGenerated = action.payload;
    },
    setGeneratedSegment: (state, action: PayloadAction<string>) => {
      state.generatedSegment = action.payload;
      // Persisting to local storage
      localStorage.setItem("generatedSegment", action.payload);
    },
  },
});

export const {
  setUpdateFlag,
  setGenerating,
  setNewGenerated,
  setGeneratedSegment,
} = subSegmentSlice.actions;
export default subSegmentSlice.reducer;

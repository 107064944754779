import { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#172e55",
    fontFamily: "Nunito Sans",
    colorInfo: "#172e55",
    borderRadius: 5,
    wireframe: true,
    boxShadowSecondary:
      "\n      0 5px 12px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ",
    colorWarning: "#f26923",
  },
  components: {
    Table: {
      headerBg: "#172e55",
      headerColor: "#FFFFFF",
      headerSortHoverBg: "#172e55",
      headerSortActiveBg: "#172e55",
      fontFamily: "Nunito Sans",
    },
  },
};

export default theme;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/authService";
import Loader from "../../components/loader/Loader";
import { colors } from "../../constants/themeConstants";

const Callback = (): any => {
  const navigate = useNavigate();
  const authService = new AuthService();

  useEffect(() => {
    if (!authService.isAuthenticated()) {
      authService.signInRedirectCallback();
    } else {
      navigate("/");
    }
  }, []);
  return <Loader color={colors.primary} cover={true} />;
};

export default Callback;

import React, { FC } from "react";
import styled from "styled-components";
import { colors } from "../../constants/themeConstants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface MaterialIconProps {
  iconName: string;
  className?: string;
  color?: string;
  iconSize?: number;
  onClick?: () => void;
  showTooltip?: boolean;
  tooltipTitle?: string;
  placement?: any;
  disabled?: boolean;
}

interface StyledContainerProps {
  iconSize?: number;
  color?: string;
  disabled?: boolean;
}

const IconContainer = styled.i<StyledContainerProps>`
  cursor: ${(props) =>
    props.disabled
      ? "not-allowed"
      : "pointer"}; // Change cursor based on disabled prop
  position: relative;
  color: ${(props) => (props.color ? props?.color : colors.secondary)};
  font-size: ${(props) => props.iconSize}px;
  opacity: ${(props) =>
    props.disabled ? 0.5 : 1}; // Reduce opacity if disabled
`;

const MaterialIcon: FC<MaterialIconProps> = ({
  iconName,
  className = "",
  color,
  iconSize,
  onClick,
  showTooltip = false,
  tooltipTitle,
  placement,
  disabled,
  ...rest
}) => {
  const icon = (
    <IconContainer
      iconSize={iconSize ?? 20}
      className={`material-icons ${className}`}
      onClick={onClick}
      color={color}
      disabled={disabled}
      {...rest}
    >
      {iconName}
    </IconContainer>
  );
  return showTooltip ? (
    <OverlayTrigger
      placement={placement}
      overlay={
        tooltipTitle ? (
          <Tooltip id={`${tooltipTitle}-tooltip`} style={{ position: "fixed" }}>
            {tooltipTitle}
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      {icon}
    </OverlayTrigger>
  ) : (
    icon
  );
};

export default MaterialIcon;

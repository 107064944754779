/* eslint-disable react-refresh/only-export-components */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface viewDetails {
  si8TopRankRequestId: string;
  si8DataRequestId: string;
  isTopTenViewDetailsPooling: boolean;
}

const initialState: viewDetails = {
  isTopTenViewDetailsPooling:
    localStorage.getItem("isTopTenViewDetailsPooling") === "true",
  si8TopRankRequestId: localStorage.getItem("si8TopRankRequestId") || "",
  si8DataRequestId: "",
};

const TopTenViewDetailsSlice = createSlice({
  name: "topTenViewDetailsSlice",
  initialState,
  reducers: {
    setTopTenViewDetailsStatus(state, action: PayloadAction<boolean>) {
      state.isTopTenViewDetailsPooling = action.payload;
      localStorage.setItem(
        "isTopTenViewDetailsPooling",
        action.payload.toString()
      );
    },
    setSi8TopRankRequestId(state, action: PayloadAction<string>) {
      state.si8TopRankRequestId = action.payload;
      localStorage.setItem("si8TopRankRequestId", action.payload);
    },

    removeTopTenViewDetailsStatus(state) {
      state.isTopTenViewDetailsPooling = false;
      state.si8TopRankRequestId = "";
      localStorage.removeItem("isTopTenViewDetailsPooling");
      localStorage.removeItem("si8TopRankRequestId");
    },
  },
});

export const {
  setTopTenViewDetailsStatus,
  setSi8TopRankRequestId,
  removeTopTenViewDetailsStatus,
} = TopTenViewDetailsSlice.actions;
export default TopTenViewDetailsSlice.reducer;

export const IDENTITY_CONFIG = {
  authority: window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL, // (string): The URL of the OIDC provider.
  client_id: window?.__RUNTIME_CONFIG__?.VITE_IDENTITY_CLIENT_ID, // (string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: window?.__RUNTIME_CONFIG__?.VITE_REDIRECT_URL, // The URI of your client application to receive a response from the OIDC provider.
  automaticSilentRenew: false, // (boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: false, // (boole
  scope: "openid profile FrostAndSullivanSi8 email offline_access",
  silent_redirect_uri: window?.__RUNTIME_CONFIG__?.VITE_SILENT_REDIRECT_URL, // (string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri:
    window?.__RUNTIME_CONFIG__?.VITE_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
  response_type: "code", // (string, default: 'id_token'): The type of response desired from the OIDC provider.
  grantType: "client_credentials",
  webAuthResponseType: "code",
  monitorSession: false,
};

function handleNullableString(value: string | undefined): string {
  return value ?? "";
}

export const METADATA_OIDC = {
  issuer: window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL,
  jwks_uri:
    handleNullableString(window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL) +
    "/.well-known/openid-configuration/jwks",
  authorization_endpoint:
    handleNullableString(window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL) +
    "/connect/authorize",
  token_endpoint:
    handleNullableString(window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL) +
    "/connect/token",
  userinfo_endpoint:
    handleNullableString(window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL) +
    "/connect/userinfo",
  end_session_endpoint:
    handleNullableString(window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL) +
    "/connect/logout-teco",
  check_session_iframe:
    handleNullableString(window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL) +
    "/connect/checksession",
  revocation_endpoint:
    handleNullableString(window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL) +
    "/connect/revocation",
  introspection_endpoint:
    handleNullableString(window?.__RUNTIME_CONFIG__?.VITE_AUTH_URL) +
    "/connect/introspect",
};

import { colors } from "../constants/themeConstants";
import { CSSProperties } from "react";

export const styleDanger: CSSProperties = {
  textAlign: "center",
  display: "inline-block",
  verticalAlign: "top",
  fontWeight: 700,
  border: "none",
  textDecoration: "none",
  fontFamily: "Nunito Sans",
  borderRadius: 8,
  cursor: "pointer",
  lineHeight: 1.5,
  transition: "0.3s ease-in-out all",
  padding: "0.530rem 1.50rem",
  fontSize: "1rem",
  background: "#f7913d",
  color: colors.white,
};

export const stylePrimary: CSSProperties = {
  textAlign: "center",
  display: "inline-block",
  verticalAlign: "top",
  fontWeight: 700,
  border: "none",
  textDecoration: "none",
  fontFamily: "Nunito Sans",
  borderRadius: 8,
  cursor: "pointer",
  lineHeight: 1.5,
  margin: "0px 10px",
  transition: "0.3s ease-in-out all",
  padding: "0.530rem 1.50rem",
  fontSize: "1rem",
  background: "#172d55",
  color: colors.white,
};

export const styleDisabled: CSSProperties = {
  ...stylePrimary,
  background: "grey",
  cursor: "not-allowed",
};

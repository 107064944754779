export const colors = {
  primary: "#f28e36",
  primaryGray: "#656565",
  formLabelGray: "#757575",
  secondary: "#172d55",
  danger: "#dc3545",
  white: "#ffffff",
  black: "#000000",
  lightGray: "#d3d3d3",
  gray: "#808080",
  blue: "#214691",
  green: "#79a140",
  yellow: "#f8f80e",
  tableRowHover: "#fafafa",
};

import { type UseQueryResult, useQuery } from "react-query";
import {
  ProfileService,
  type Volo_Abp_Account_ProfileDto,
} from "../../services/frost-si8-services";

const useGetCurrentProfile = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): UseQueryResult<Volo_Abp_Account_ProfileDto | undefined, unknown> => {
  const currentProfileData = useQuery(["getProfileData"], async () => {
    return await handleRequest(ProfileService.getCoreApiApiAccountMyProfile());
  });

  return currentProfileData;
};

export default useGetCurrentProfile;

import { FC, ReactNode } from "react";
import Modal from "react-bootstrap/Modal";

interface CustomModalProps {
  children?: ReactNode;
  footerButton?: ReactNode;
  title?: string;
  submit?: () => void;
  [key: string]: any;
  close?: boolean;
  className?: string;
}

const CustomModal: FC<CustomModalProps> = ({
  children,
  title,
  close = true,
  className,
  footerButton,
  ...props
}) => {
  return (
    <Modal
      {...props}
      size={props?.size || "lg"}
      aria-labelledby="frost-modal"
      centered
      className={className ?? ""}
      backdrop="static"
    >
      {title && (
        <Modal.Header closeButton={close}>
          <Modal.Title id="frost-modal">{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {footerButton && <Modal.Footer>{footerButton}</Modal.Footer>}{" "}
    </Modal>
  );
};

export default CustomModal;

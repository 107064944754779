import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import CustomModal from "../../components/modal";
import useCustomModal from "../../hooks/customHooks/useCusomModal";
import UpdateView from "./updateViewModal";

interface NotificationProps {
  markAsRead: any;
  badgeNotif: any;
  notifStatusChange: (args) => void;
  mutate: (args) => void;
}

const Notification = ({
  markAsRead,
  badgeNotif,
  notifStatusChange,
  mutate,
}: NotificationProps) => {
  const { showModal, openModal, closeModal } = useCustomModal();
  const [notificationData, setNotificationData] = useState<any>("");

  const openNotification = (id: string | undefined) => {
    const notification = badgeNotif?.find(
      (item) => item?.id === id
    )?.description;
    openModal();
    setNotificationData(notification);
  };

  const markAllRead = (ids: any) => {
    if (ids.length > 0) {
      mutate(ids);
    }
  };

  const openNotif = (item) => {
    openNotification(item?.id);
    const notificationData = {
      notificationId: item.id,
      isSeen: !item.isSeen,
      isPopupOpened: item.isPopupOpened,
    };
    notifStatusChange([notificationData]);
    openModal();
  };

  return (
    <>
      {badgeNotif?.length > 0 ? (
        <ul className="notif-dropdown">
          <div className="notif-header">
            <span className="notif-title">Notifications</span>
            <div
              className="notif-action"
              onMouseDown={() => markAllRead(markAsRead)}
            >
              Mark All As Read
            </div>
          </div>
          <div>
            {badgeNotif?.map((item, index) =>
              item ? (
                <li key={index}>
                  <button
                    className="item-button"
                    onClick={() => openNotif(item)}
                  >
                    <h4 className="notif-label">{item?.description?.title}</h4>
                    <p className="notif-message">
                      {item?.description?.message}
                    </p>
                    <span className="notif-date">
                      {moment(item?.creationTime).format("YYYY-MM-DD")}
                    </span>
                  </button>
                </li>
              ) : (
                <li key={index}>No Notifications</li>
              )
            )}
          </div>
          <Link
            to="/update"
            style={{
              textDecoration: "none",
              position: "sticky",
              bottom: "0",
            }}
            className="all-notif"
          >
            <div className="no-notif-label">View All</div>
          </Link>
        </ul>
      ) : (
        <>
          <div className="notif-dropdown">
            <div className="no-notif">No new Notification</div>
            {/* <div className="notif-header">
              <span className="notif-title">Notification</span>
              <span
                className="notif-action"
                onClick={() => markAllRead(markAsRead)}
              >
                Mark As Read
              </span>
            </div> */}
            <Link
              to="/update"
              style={{ textDecoration: "none", bottom: "0" }}
              className="all-notif"
            >
              <div className="no-notif-label">View All</div>
            </Link>
          </div>
        </>
      )}

      {showModal && (
        <CustomModal
          title={"View Notice"}
          show={showModal}
          onHide={() => {
            closeModal();
          }}
        >
          <UpdateView closeUpdate={closeModal} rowData={notificationData} />
        </CustomModal>
      )}
    </>
  );
};

export default Notification;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: Infinity,
      retry: false,
      retryDelay: 300,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Provider store={store}>
        <ToastContainer
          autoClose={5000}
          draggable={true}
          pauseOnHover={true}
          pauseOnFocusLoss={false}
          hideProgressBar={false}
          position={"top-center"}
          theme={"light"}
          // style={{ zIndex: 100000000000000000 }}
        />
        <App />
      </Provider>
    </BrowserRouter>
  </QueryClientProvider>
);

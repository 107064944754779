import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store/store';

interface NotifyState {
  notify: boolean;
}

const notifyState: NotifyState = {
  notify: false,
};

const notifySlice = createSlice({
  name: 'notify',
  initialState: notifyState,
  reducers: {
    setNotify: (state, { payload: { notify } }: PayloadAction<{ notify: boolean }>) => {
      state.notify = notify;
    },
  },
});

export const { setNotify } = notifySlice.actions;

export default notifySlice.reducer;

export const selectNotify = (state: RootState): boolean => state?.notify?.notify;

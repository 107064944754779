export const RELOAD_PERMISSION_MESSAGE =
  "Your permission has changed. The current page will be refreshed to load your new permissions.";
export const REDIRECT_PERMISSION_MESSAGE = "Your session has been expired.";

export const tableData = [
  {
    id: 1215,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Innovative Business Models",
    event:
      "Adoption of AI and machine learning in unmanned systems for predictive maintenance and autonomous operations",
    impactScore: "8",
    impactStartYear: "2024",
    impactDuration: "5",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "AI and machine learning can help in predicting maintenance needs, reducing downtime, and enabling autonomous operations, thereby increasing efficiency and reducing costs. (Source: McKinsey, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1216,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Innovative Business Models",
    event: "Implementation of pay-per-use business models for drone services",
    impactScore: "7",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Moderate",
    siDetail:
      "Pay-per-use models can provide flexibility to customers and generate steady revenue streams for companies. This model is particularly suitable for drone services like aerial photography, surveying, and inspections. (Source: Deloitte, 2022)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1217,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Innovative Business Models",
    event: "Partnerships with e-commerce companies for drone delivery services",
    impactScore: "9",
    impactStartYear: "2025",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Partnerships with e-commerce companies can open up new revenue streams for unmanned system companies. Drone delivery can provide faster and more efficient delivery services. (Source: PwC, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1218,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Compression of Value Chains",
    event:
      "Integration of IoT in unmanned systems for real-time data collection and analysis",
    impactScore: "8",
    impactStartYear: "2024",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "IoT integration can enable real-time data collection and analysis, reducing the time taken for decision making and improving operational efficiency. (Source: Gartner, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1219,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Compression of Value Chains",
    event: "Adoption of blockchain for secure data sharing in unmanned systems",
    impactScore: "7",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Moderate",
    siDetail:
      "Blockchain can provide a secure platform for data sharing, reducing the risk of data breaches and improving trust among stakeholders. (Source: IBM, 2022)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1220,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Compression of Value Chains",
    event:
      "Implementation of direct-to-consumer sales models for unmanned systems",
    impactScore: "6",
    impactStartYear: "2023",
    impactDuration: "5",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Low",
    siDetail:
      "Direct-to-consumer sales models can reduce the number of intermediaries, reducing costs and improving customer experience. (Source: Bain & Company, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1221,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Transformative Mega Trends",
    event:
      "Increasing use of unmanned systems for disaster management and humanitarian aid",
    impactScore: "9",
    impactStartYear: "2024",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Unmanned systems can play a crucial role in disaster management and humanitarian aid, providing services like search and rescue, damage assessment, and supply delivery. (Source: World Economic Forum, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1222,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Transformative Mega Trends",
    event:
      "Rise of smart cities and the role of unmanned systems in urban management",
    impactScore: "8",
    impactStartYear: "2025",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Smart cities are expected to rely heavily on unmanned systems for tasks like traffic management, surveillance, and maintenance. This can open up new opportunities for unmanned system companies. (Source: McKinsey, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1223,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Transformative Mega Trends",
    event:
      "Increasing environmental concerns and the role of unmanned systems in environmental monitoring",
    impactScore: "7",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Moderate",
    siDetail:
      "Unmanned systems can be used for environmental monitoring, helping in tasks like wildlife tracking, pollution monitoring, and forest fire detection. (Source: Nature, 2022)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1224,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Disruptive Technologies",
    event: "Development of autonomous drones with advanced AI capabilities",
    impactScore: "10",
    impactStartYear: "2024",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Autonomous drones with advanced AI capabilities can perform complex tasks without human intervention, opening up new applications and markets. (Source: MIT Technology Review, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1225,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Disruptive Technologies",
    event: "Advancements in battery technology for longer drone flight times",
    impactScore: "8",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Moderate",
    siDetail:
      "Advancements in battery technology can enable longer flight times for drones, increasing their operational efficiency and range. (Source: IEEE Spectrum, 2022)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1226,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Disruptive Technologies",
    event: "Development of swarm technology for coordinated drone operations",
    impactScore: "9",
    impactStartYear: "2025",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Swarm technology can enable coordinated operations of multiple drones, increasing their efficiency and capabilities. This can be particularly useful in applications like surveillance, search and rescue, and delivery. (Source: Nature, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1227,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Internal Challenges",
    event: "Adapting to regulatory changes for unmanned systems",
    impactScore: "7",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Moderate",
    siDetail:
      "Regulatory changes can pose challenges for unmanned system companies. They need to adapt to these changes to ensure compliance and avoid penalties. (Source: PwC, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1228,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Internal Challenges",
    event: "Managing cybersecurity risks in unmanned systems",
    impactScore: "8",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Cybersecurity risks can pose a major threat to unmanned systems. Companies need to implement robust cybersecurity measures to protect their systems and data. (Source: Deloitte, 2022)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1229,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Internal Challenges",
    event: "Developing skills and capabilities for advanced unmanned systems",
    impactScore: "6",
    impactStartYear: "2023",
    impactDuration: "5",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Low",
    siDetail:
      "As unmanned systems become more advanced, companies need to develop the necessary skills and capabilities to design, manufacture, and operate these systems. (Source: McKinsey, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1230,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Competitive Intensity",
    event: "Competition from tech startups in the unmanned systems space",
    impactScore: "8",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Tech startups are entering the unmanned systems space with innovative solutions, posing a threat to established companies. (Source: Forbes, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1231,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Competitive Intensity",
    event: "Competition from digital platforms offering drone services",
    impactScore: "7",
    impactStartYear: "2024",
    impactDuration: "10",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Moderate",
    siDetail:
      "Digital platforms offering drone services can provide competition to traditional unmanned system companies. (Source: Bain & Company, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1232,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Competitive Intensity",
    event: "Competition from companies offering low-cost unmanned systems",
    impactScore: "6",
    impactStartYear: "2023",
    impactDuration: "5",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Low",
    siDetail:
      "Companies offering low-cost unmanned systems can pose a threat to companies with higher-priced offerings. (Source: PwC, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1233,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Geopolitical Chaos",
    event: "Impact of trade wars on the supply chain of unmanned systems",
    impactScore: "7",
    impactStartYear: "2023",
    impactDuration: "5",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Moderate",
    siDetail:
      "Trade wars can disrupt the supply chain of unmanned systems, affecting their production and delivery. (Source: Deloitte, 2022)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1234,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Geopolitical Chaos",
    event:
      "Impact of political instability on the operation of unmanned systems",
    impactScore: "8",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Political instability can affect the operation of unmanned systems, particularly in conflict zones. (Source: World Economic Forum, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1235,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Geopolitical Chaos",
    event: "Impact of natural disasters on the deployment of unmanned systems",
    impactScore: "6",
    impactStartYear: "2023",
    impactDuration: "5",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Low",
    siDetail:
      "Natural disasters can affect the deployment of unmanned systems, particularly for disaster management and humanitarian aid. (Source: Nature, 2022)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1236,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Industry Convergence",
    event:
      "Collaboration with telecom companies for 5G-enabled unmanned systems",
    impactScore: "9",
    impactStartYear: "2024",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Collaboration with telecom companies can enable the development of 5G-enabled unmanned systems, providing faster and more reliable connectivity. (Source: Gartner, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1237,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Industry Convergence",
    event: "Collaboration with automotive companies for autonomous vehicles",
    impactScore: "8",
    impactStartYear: "2025",
    impactDuration: "10",
    durationCurve: "Exponential",
    potentialImpactOnRevenue: "High",
    siDetail:
      "Collaboration with automotive companies can enable the development of autonomous vehicles, opening up new opportunities for unmanned system companies. (Source: McKinsey, 2023)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
  {
    id: 1238,
    dts: "2023-09-19 11:10:56",
    practisearea: "Aerospace & Defense",
    transformationjourney: "Unmanned System",
    georegion: "Asia",
    strategicImperative: "Industry Convergence",
    event:
      "Collaboration with energy companies for drone-based inspection services",
    impactScore: "7",
    impactStartYear: "2023",
    impactDuration: "10",
    durationCurve: "Linear",
    potentialImpactOnRevenue: "Moderate",
    siDetail:
      "Collaboration with energy companies can enable the provision of drone-based inspection services, providing a new revenue stream for unmanned system companies. (Source: Deloitte, 2022)",
    sourceName: "Open AI",
    isedited: null,
    addedmanually: null,
    updatedby: null,
    aitimestamp: "2023-09-19 11:10:56",
    aiversion: null,
    status: null,
    requestid: 117,
  },
];
export const PERMISSION_LIST = [
  {
    id: "User",
    label: "User Management",
  },
  {
    id: "Role",
    label: "Role Management",
  },
  {
    id: "Feedback",
    label: "Feedback Management",
  },
  {
    id: "FeedbackDelete",
    label: "Feedback Management",
  },
  {
    id: "UpdateNotice",
    label: "Notice Management",
  },
];

export interface TableParamsType {
  searchKeyword?: string;
  sortOrder?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
}

import styled from "styled-components";

export const UpdateWrapper = styled.div`
  .container {
    overflow: hidden;
    padding: 10px;
  }
  .subject,
  .version,
  .description {
    margin-top: 10px;
  }

  .subject {
    width: 100%;
  }
  .version {
    width: 100%;
  }

  .description {
    width: 100%;
    height: 50%;
  }

  .button-holder {
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
  }

  .cancel-btn {
    margin-left: 20px;
  }

  .submit-btn {
    margin-left: 50px;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(23, 45, 85, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background: #172d55;
  }
`;

export const CustomLoader = styled.span`
  &.loader {
    margin: 16px 0;
    width: 100px;
    aspect-ratio: 1;
    border-radius: 99%;
    background: radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px
        no-repeat,
      conic-gradient(#0000 30%, #ffa516);
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 8px),
      #000 0
    );
    animation: l13 0.8s infinite linear;
  }
  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
`;

export const LoaderWrapper = styled.span`
  background-color: #fffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: auto;
`;

export const ViewWrapper = styled.div<{ notif: boolean }>`
  display: flex;
  flex-direction: column;
  height: "60vh";
  justify-content: space-evenly;

  .carousel-control-next {
    right: -55px;
  }
  .carousel-control-prev {
    left: -55px;
  }
  .carousel-indicators {
    bottom: -20px;
  }
  .header {
    margin: 8px 5px;
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 24px;
    word-break: break-word;
    max-width: 37rem;
  }

  .version-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-word;
    max-height: 5rem;
    max-width: 9rem;

    span.version {
      word-wrap: break-word;
      overflow-y: auto;
      // max-height: 100%;
    }
  }

  .description {
    padding: 14px;
    margin: 19px 12px;
    width: 100%;
    max-width: 741px;
    height: 351px;
    overflow: auto;
    border-radius: 10px;
    box-shadow: -2px 0px 46px 2px rgba(153, 153, 153, 0.42);
    word-break: break-word;
    // word-break: break-all;
  }

  @media (max-width: 767px) {
    .title {
      font-size: 18px;
    }

    .description {
      height: 370px;
      padding: 15px;
      margin: 0px 0px;
      word-break: break-word;
      // word-break: break-all;
    }
  }

  @media (max-width: 990px) {
    .carousel-control-next {
      right: -40px;
    }
    .carousel-control-prev {
      left: -38px;
    }
    .carousel-indicators {
      bottom: -16px;
    }
    .title {
      font-size: 18px;
    }

    .description {
      height: 370px;
      padding: 15px;
      margin: 16px 2px;
      word-break: break-word;
    }
  }

  .cancelButton {
    margin-top: 20px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(23, 45, 85, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background: #172d55;
  }
`;

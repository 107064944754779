import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import Spinners from '../../components/spinner';
import AuthService from "../../services/authService";
import Loader from "../../components/loader/Loader";
import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

function Login(): JSX.Element {
  const authService = new AuthService();
  const navigate = useNavigate();

  useEffect(() => {
    if (authService.isAuthenticated()) {
      navigate("/");
    } else {
      authService.signInRedirect();
    }
  }, []);

  return <Loader color={colors.primary} cover={true} />;
}

export default Login;

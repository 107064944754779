/* eslint-disable no-unused-vars */
import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { RouteList } from "./routesList";
import { selectIsAuthenticated } from "../pages/auth/authSlice";
import AuthService from "../services/authService";
import { useSelector } from "react-redux";
import Callback from "../pages/auth/callback";
import Login from "../pages/auth/login";
import LoadingSpinner from "../components/suspenseSpinner";

const Strategic = lazy(() => import("../pages/Strategic-Imperative"));
const GrowthOpportunity = lazy(() => import("../pages/growthOpportunity"));
const Home = lazy(() => import("../pages/home/home"));
const GrowthTable = lazy(() => import("../pages/growthDefaultView"));
const GraftTable = lazy(() => import("../pages/GrowthSavedReportTable"));
const ViewGrowthTable = lazy(() => import("../pages/ViewGrowthTable"));
const GrowthHistory = lazy(() => import("../pages/GrowthHistory/index"));
const DetailSavedReport = lazy(
  () => import("../pages/DetailSavedReport/index")
);
const RoutesContainer = () => {
  const authService = new AuthService();
  const isAuthenticated =
    useSelector(selectIsAuthenticated) || authService.isAuthenticated();

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/callback" element={<Callback />} />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          {RouteList.map((route, i) => (
            <Route path={route.path} element={<route.component />} key={i} />
          ))}
          <Route path="/" element={<Home />}>
            <Route index element={<Navigate to="transformation" />} />
            <Route path="transformation" element={<Strategic />} />
            {/* <Route
              path="saved-report-Table/:id"
              element={<DetailSavedReport />}
            /> */}
            <Route path="growth-opportunity" element={<GrowthOpportunity />}>
              <Route index element={<Navigate to="growth-Table" />} />
              <Route path="growth-Table" element={<GrowthTable />} />
              <Route path="saved-report-Table" element={<GraftTable />} />
              <Route path="growth-history" element={<GrowthHistory />} />
              <Route path="growth-Table/:id" element={<ViewGrowthTable />} />
              <Route
                path="saved-report-Table/:id"
                element={<DetailSavedReport />}
              />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/page-not-found" replace />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutesContainer;

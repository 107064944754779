import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { colors } from "../../constants/themeConstants";
import useCustomModal from "../../hooks/customHooks/useCusomModal";
import { selectAuthRoutesList } from "../../pages/configuration/routeSlice";
import Notification from "../../pages/update/notification";
import MaterialIcon from "../materialIcon/materialIcon";
import { NavbarWrapper, StyledSettingDropdown } from "./navbar.styled";
import UserBox from "./userBox";
import {
  useGetAllBadgeNotification,
  usePostMarkAllRead,
  usePutNotifStatusChange,
} from "../../hooks";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import { color } from "html2canvas/dist/types/css/types/color";

const Navbar = () => {
  const userAuthList = useSelector(selectAuthRoutesList);

  const [active, setActive] = useState<boolean>(false);
  const [notifBadge, setNotifBadge] = useState<any>();
  const handleOpen = () => {
    setActive(!active);
  };
  const { showModal, openModal, closeModal } = useCustomModal();
  const { handleRequest } = useFrostSi8Api();
  const { data: badgeNotifData } = useGetAllBadgeNotification(handleRequest);
  const { mutate } = usePostMarkAllRead(handleRequest);
  const { mutate: notifStatusChange, isLoading: notifLoading } =
    usePutNotifStatusChange(handleRequest);
  const BadgeNotif = badgeNotifData?.data;
  const markAsRead = BadgeNotif?.map((item) => {
    return item.id;
  });

  useEffect(() => {
    if (BadgeNotif) {
      const unreadNotif = BadgeNotif?.some((item) => !item.isSeen);
      setNotifBadge(unreadNotif);
    }
  }, [BadgeNotif, setNotifBadge]);

  return (
    <NavbarWrapper className="header bg-white w-100">
      <div className="container">
        <div className="main-logo">
          <Link to="/">
            <img src={logo} />
          </Link>
        </div>
        <div className="userBox-container">
          {/* <ul className="navbar-nav"> */}
          {/* <Link to="/growth-opportunity" className="nav-link">
              <MaterialIcon
                placement={"left"}
                iconName="arrow_warm_up"
                tooltipTitle="Growth-Opportunity"
                showTooltip={true}
                iconSize={25}
                color={colors.gray}
              />
            </Link>
          </ul> */}

          <ul className="navbar-nav">
            <Link to="/feedback" className="nav-link">
              <MaterialIcon
                placement={"left"}
                iconName="feedback"
                tooltipTitle="Feedback"
                showTooltip={true}
                iconSize={22}
                color={colors.gray}
              />
            </Link>
          </ul>
          <StyledSettingDropdown
            onBlur={() => {
              setActive(false);
            }}
            hasdata={BadgeNotif && BadgeNotif?.length > 0}
            className={`setting-block ${active ? "active" : ""}`}
          >
            {userAuthList?.currentUser?.roles[0] === "admin" ? (
              <>
                <button className="setting-opener pe-2" onClick={handleOpen}>
                  <MaterialIcon
                    className="setting-icon"
                    placement={"left"}
                    iconName="settings"
                    tooltipTitle="Settings"
                    showTooltip={true}
                    iconSize={22}
                    color={active ? colors.secondary : colors.gray}
                  />
                </button>
                <ul className="setting-dropdown">
                  <li>
                    <button>
                      <Link to="/user-management" className="link">
                        <MaterialIcon
                          placement={"left"}
                          className="icon"
                          iconName="account_box"
                          iconSize={18}
                          color={colors.gray}
                        />
                        <span
                          className="setting-label"
                          style={{ verticalAlign: "middle" }}
                        >
                          User Management
                        </span>
                      </Link>
                    </button>
                    <button>
                      <Link to="/update" className="link">
                        <MaterialIcon
                          placement={"left"}
                          className="icon"
                          iconName="campaign"
                          iconSize={18}
                          color={colors.gray}
                        />
                        <span
                          className="setting-label"
                          style={{ verticalAlign: "middle" }}
                        >
                          Update
                        </span>
                      </Link>
                    </button>
                  </li>
                </ul>
              </>
            ) : userAuthList?.currentUser?.roles === undefined ? (
              ""
            ) : (
              <>
                <button
                  className={`notif-opener pe-2`}
                  onClick={() => {
                    handleOpen();
                    openModal();
                  }}
                >
                  {notifBadge ? <div className={"unread"}></div> : null}
                  <MaterialIcon
                    placement={"left"}
                    className="icon"
                    tooltipTitle="Notification"
                    showTooltip={true}
                    iconName="notifications"
                    iconSize={20}
                    color={colors.gray}
                  />
                </button>
                {showModal && (
                  <Notification
                    markAsRead={markAsRead}
                    badgeNotif={BadgeNotif}
                    notifStatusChange={notifStatusChange}
                    mutate={mutate}
                  />
                )}
              </>
            )}
          </StyledSettingDropdown>
          <UserBox />
        </div>
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;
